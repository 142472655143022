/* Gogo Language Texts

Table of Contents

01.General
02.User Login, Logout, Register
03.Menu
04.Dashboards
05.Pages
06.Applications
  06.01.Chat
  06.02.Survey
  06.03.Todo
07.UI
  07.01.Alerts
  07.02.Badges
  07.03.Buttons
  07.04.Cards
  07.05.Carousel
  07.06.Charts
  07.07.Collapse
  07.08.Dropdowns
  07.09.Editors
  07.10.Forms
  07.11.Form Components
  07.12.Icons
  07.13.Input Groups
  07.14.Jumbotron
  07.15.Modal
  07.16.Navigation
  07.17.Popover & Tooltip
  07.18.Sortable
  07.19.Maps
  07.20.Tables
  07.21.Wizards
*/

module.exports = {
    /* 01.General */
    "general.copyright": "TteeNbe © All Rights Reserved.",

    /* 02.User, Login, Register */
    "user.email": "E-mail",
    "user.password": "Password",
    "user.email-error-1": "Please enter your email address",
    "user.email-error-2": "Invalid email address",
    "user.password-error-1": "Please enter your password",
    "user.password-error-2": "Value must be longer than 3 characters",

    "user.title": "INMOFUERTE",
    "user.login-title": "Login",
    "user.register-title": "Register",
    "user.forgot-password": "Forgot Password",
    "user.forgot-password-question": "Forget password?",
    "user.fullname": "Full Name",
    "user.login-button": "LOGIN",
    "user.register-button": "REGISTER",
    "user.reset-password-button": "RESET",
    "user.buy": "BUY",
    "user.username": "Username",

    /* 02.TOPNAV ADMINISTRADOR */
    "topnav.admin.logout": "Logout",
    "topnav.admin.profile": "Profile",
    "topnav.admin.dark-mode": "Dark Mode",
    "topnav.admin.light-mode": "Light Mode",
    "topnav.admin.full-screen": "Full Screen",
    "topnav.admin.normal-screen": "Normal Screen",

    /* 02.MENU SIDEBAR */
    "menu.sidebar.users": "Users",
    "menu.sidebar.users-1": "List Users",
    "menu.sidebar.users-2": "List Users 2",
    "menu.sidebar.other": "other",
    "menu.sidebar.other-1": "List other",






    "general.texto-search": "Search",
    "general.texto-editar": "Edit",
    "general.texto-eliminar": "Delete",
    "general.texto-nuevo": "New",
    "general.texto-ver": "View",
    "general.texto-seguro": "Its seguro remove",
    "general.texto-si": "Yes",
    "general.texto-no": "Not",
    "general.texto-cancel": "Cancel",
    "general.texto-cancelar": "Cancel",
    "general.texto-agregar": "Add",
    "general.texto-listar": "List",
    "general.texto-actualizar": "Update",
    "general.texto-salir": "Done",
    "general.texto-Listo": "Done",
    "general.texto-Limpiar": "Clear",
































    











    
    /* 03.Menu */
    "menu.ubicacion": "Location",
    "menu.ciudad": "Contry",
    "menu.tipo_oferta": "Type of offer",
    "menu.tipo_inmueble": "Property type",
    "menu.inmueble": "Property",
    "menu.proyecto": "Project",
    "menu.list": "List",
    "menu.slider": "Slider",

    "menu.app": "Home",
    "menu.dashboards": "Dashboards",
    "menu.gogo": "Gogo",
    "menu.start": "Start",
    "menu.second-menu": "Second Menu",
    "menu.second": "Second",
    "menu.ui": "UI",
    "menu.charts": "Charts",
    "menu.chat": "Chat",
    "menu.survey": "Survey",
    "menu.todo": "Todo",
    "menu.search": "Search",
    "menu.docs": "Docs",
    "menu.blank-page": "Blank Page",

    "menu.user": "User",
    "menu.users": "Users",
    "menu.list_users": "Users",

    "menu.user-profile": "Profile",
    "menu.user-profile-1": "Data",

    "menu.user-list-1": "Account",
    "menu.user-list-2": "Features",
    "menu.user-list-3": "History",
    "menu.user-list-4": "Support",
    "menu.user-list-5": "Sign out",

    "menu.agente": "View",
    "menu.administrador": "Edit",
    "menu.archivos": "Files",
    "menu.archivo_contratos": "Docs",

    "menu.profile": "Profile",
    "menu.left-users": "Users",
    "menu.left-users-list-1": "Users list",
    "menu.usuario_historial_tabla": "User history table",
    "menu.nivel_users": "Level User",
    "menu.left-users-list-2": "User history table",
    "menu.usuario_historial_inmueble_busqueda": "User History Property Search",
    "menu.left-users-list-3": "User History Property Search",
    "menu.contactos": "Contactos",

    "menu.ventausuario_list": "Venta por vendedor",
    "menu.ventaconsignador_list": "Venta por consignador",
    "menu.ventaporterceros_list": "Venta por terceros",

    "menu.estadistica": "Estatistic",
    "menu.left-estadistica-1": "General Estatistic",
    "menu.left-estadistica-2": "usuario list",
    "menu.left-estadistica-3": "inmofuerte",
    "menu.left-estadistica-4": "Equals users",
    "menu.left-estadistica-5": "Equals Consignador",
    "menu.left-estadistica-6": "Venta por terceros",

    "menu.zone": "Zone",
    "menu.real_state": "Real State",
    "menu.inmueble-vendido": "Real State Sale",
    "menu.inmueble-vendido-agente": "Real State Sale",
    "menu.proyecto-vendido": "Proyect Sale",
    "menu.proyecto-vendido-agente": "Proyect Sale",
    "menu.left-real_state": "Real State",
    "menu.left-real_state-list-0": "Proyect",
    "menu.left-real_state-list-0-1": "Proyect Sales",
    "menu.left-real_state-list-1-agente": "Real state",
    "menu.left-real_state-list-1": "Real state",
    "menu.left-real_state-list-1-1": "Real state Sales",
    "menu.left-real_state-list-2": "Location",
    "menu.left-real_state-list-3": "Property type",
    "menu.left-real_state-list-4": "type of offer",
    "menu.left-real_state-list-5": "Contry",

    "menu.left-file-list-1": "Web",
    "menu.left-file-list-2": "Docs",
    "menu.left-file-list-3": "Inmofuerte",
    "menu.left-file-list-4": "Contactos",
    "menu.left-file-list-5": "Capacitacion",

    "menu.archivo_capacitaciones": "Capacitaciones",

    "menu.file": "Files",
    "menu.page_web": "Page Web",
    "menu.left-web": "Page Web",
    "menu.nosotros": "About",
    "menu.left-web-list-1": "About",
    "menu.contacto_sucursal": "Branch Contact",
    "menu.left-web-list-2": "Branch Contact",
    "menu.horarios_atencion": "Attention Hours",
    "menu.left-web-list-3": "Attention Hours",
    "menu.contacto_general": "General Contact",
    "menu.left-web-list-4": "General Contact",
    "menu.top_diez": "Top Ten",
    "menu.left-web-list-5": "Top Ten",
    "menu.equipo_nosotros": "Team About",
    "menu.left-web-list-6": "Team About",
    "menu.left-web-list-7": "Slider",

    "menu.left-clientes": "Customers",
    "menu.clientes": "Customers",
    "menu.inmueble_visitado": "Visited Properties",
    "menu.left-clientes-list-1": "Visited Properties",
    "menu.historial_busqueda_web": "Web Search History",
    "menu.left-clientes-list-2": "Web Search History",

    "menu.left-pendientes": "Earring",
    "menu.pendientes": "Earring",
    "menu.reclutamiento": "Recruitment",
    "menu.reclutamientolist": "Recruitment List",
    "menu.left-pendientes-list-1": "Recruitment",
    "menu.proyecto_pendiente": "Pending Project",
    "menu.inmueble_pendiente": "Pending Property",
    "menu.inmueble_agente": "Real State",
    "menu.left-pendientes-list-2": "Pending Property",
    "menu.left-pendientes-list-3": "Pending Proyect",
    "menu.left-pendientes-list-4": "Pending Consignador",
    "menu.left-pendientes-list-5": "Pending Avaluo",
    "menu.left-pendientes-list-6": "Avaluo",
    "menu.avaluo": "Avaluo",
    /* 04.Error Page */
    "pages.error-title": "Ooops... looks like an error occurred!",
    "pages.error-code": "Error code",
    "pages.go-back-home": "GO BACK HOME",

    /////////////////////////////////////////////BORRAR ABAJO/////////////////////////////////////////////
    /////////////////////////////////////////////BORRAR ABAJO/////////////////////////////////////////////
    /////////////////////////////////////////////BORRAR ABAJO/////////////////////////////////////////////
    /* 02.User Login, Logout, Register */
    "user.nombre": "Nombre",
    "user.paterno": "Paterno",
    "user.materno": "Materno",
    "user.celular": "Celular",

    "user.new-password-again": "New Password Again",
    "user.new-password": "New Password",
    "user.reset-password": "Reset Password",
    /* 03.Menu */
    "menu.default": "Default",
    "menu.analytics": "Analytics",
    "menu.ecommerce": "Ecommerce",
    "menu.content": "Content",
    "menu.pages": "Pages",
    "menu.data-list": "Data List",
    "menu.thumb-list": "Thumb List",
    "menu.image-list": "Image List",
    "menu.details": "Details",
    "menu.login": "Login",
    "menu.register": "Register",
    "menu.forgot-password": "Forgot Password",
    "menu.error": "Error",
    "menu.applications": "Applications",
    "menu.alerts": "Alerts",
    "menu.badges": "Badges",
    "menu.buttons": "Buttons",
    "menu.cards": "Cards",
    "menu.carousel": "Carousel",
    "menu.collapse": "Collapse",
    "menu.dropdowns": "Dropdowns",
    "menu.editors": "Editors",
    "menu.form-layouts": "Form Layouts",
    "menu.form-components": "Form Components",
    "menu.form-validations": "Form Validations",
    "menu.form-wizard": "Form Wizard",
    "menu.icons": "Icons",
    "menu.input-groups": "Input Groups",
    "menu.jumbotron": "Jumbotron",
    "menu.modal": "Modal",
    "menu.navigation": "Navigation",
    "menu.popover-tooltip": "Popover & Tooltip",
    "menu.sortable": "Sortable",
    "menu.tables": "Tables",
    "menu.menu": "Menu",
    "menu.subhidden": "Subhidden",
    "menu.hidden": "Hidden",
    "menu.visible": "Visible",
    "menu.maps": "Maps",
    "menu.landingpage": "Landing Page",
    "menu.multipage-home": "Multipage Home",
    "menu.singlepage-home": "Singlepage Home",
    "menu.about": "About",
    "menu.auth-login": "Auth Login",
    "menu.auth-register": "Auth Register",
    "menu.reset-password": "Reset Password",
    "menu.blog": "Blog",
    "menu.blog-list": "Blog List",
    "menu.blog-detail": "Blog Detail",
    "menu.careers": "Careers",
    "menu.confirmation": "Confirmation",
    "menu.contact": "Contact",
    "menu.features": "Features",
    "menu.prices": "Prices",
    "menu.videos": "Videos",
    "menu.mailing": "Mailing",
    "menu.invoice": "Invoice",
    "menu.types": "Menu Types",
    "menu.levels": "Menu Levels",
    "menu.third-level-1": "Third Level 1",
    "menu.third-level-2": "Third Level 2",
    "menu.third-level-3": "Third Level 3",
    "menu.faq": "Faq",
    "menu.knowledge-base": "Knowledge Base",
    "menu.authorization": "Authorization",
    "menu.product": "Product",
    "menu.miscellaneous": "Miscellaneous",
    "menu.portfolio": "Portfolio",
    "menu.social": "Social",
    "menu.details-alt": "Details Alt",
    "menu.forms": "Forms",
    "menu.components": "Components",
    "menu.layouts": "Layouts",
    "menu.validations": "Validations",
    "menu.wizard": "Wizard",

    /* 04.Dashboards */
    "dashboards.pending-orders": "Pending Orders",
    "dashboards.completed-orders": "Completed Orders",
    "dashboards.refund-requests": "Refund Requests",
    "dashboards.new-comments": "New Comments",
    "dashboards.sales": "Sales",
    "dashboards.orders": "Orders",
    "dashboards.refunds": "Refunds",
    "dashboards.recent-orders": "Recent Orders",
    "dashboards.product-categories": "Product Categories",
    "dashboards.cakes": "Cakes",
    "dashboards.tickets": "Tickets",
    "dashboards.calendar": "Calendar",
    "dashboards.best-sellers": "Best Sellers",
    "dashboards.website-visits": "Website Visits",
    "dashboards.unique-visitors": "Unique Visitors",
    "dashboards.this-week": "This Week",
    "dashboards.last-week": "Lat Week",
    "dashboards.this-month": "This Month",
    "dashboards.conversion-rates": "Conversion Rates",
    "dashboards.per-session": "Per Session",
    "dashboards.profile-status": "Profile Status",
    "dashboards.payment-status": "Payment Status",
    "dashboards.work-progress": "Work Progress",
    "dashboards.tasks-completed": "Tasks Completed",
    "dashboards.payments-done": "Payments Done",
    "dashboards.order-stock": "Order - Stock",
    "dashboards.categories": "Categories",
    "dashboards.quick-post": "Quick Post",
    "dashboards.title": "Title",
    "dashboards.content": "Content",
    "dashboards.category": "Category",
    "dashboards.save-and-publish": "Save and Publish",
    "dashboards.top-viewed-posts": "Top Viewed Posts",
    "dashboards.posts": "Posts",
    "dashboards.pending-for-publish": "Pending for publish",
    "dashboards.users": "Users",
    "dashboards.on-approval-process": "On approval process",
    "dashboards.alerts": "Alerts",
    "dashboards.waiting-for-notice": "Waiting for notice",
    "dashboards.files": "Files",
    "dashboards.pending-for-print": "Pending for print",
    "dashboards.logs": "Logs",
    "dashboards.gogo": "GOGO",
    "dashboards.magic-is-in-the-details": "MAGIC IS IN THE DETAILS",
    "dashboards.yes-it-is-indeed": "Yes, it is indeed!",
    "dashboards.advanced-search": "Advanced Search",
    "dashboards.toppings": "Toppings",
    "dashboards.type": "Type",
    "dashboards.keyword": "Keyword",
    "dashboards.search": "Search",
    "dashboards.top-rated-items": "Top Rated Items",

    /* 05.Pages */
    "pages.add-new": "ADD NEW",
    "pages.add-new-modal-title": "Add New Item",
    "pages.display-options": "Search Filters",
    "pages.orderby": "Order By : ",
    "pages.product-name": "Product Name",
    "pages.category": "Category",
    "pages.description": "Description",
    "pages.status": "Status",
    "pages.cancel": "Cancel",
    "pages.submit": "Submit",
    "pages.delete": "Delete",
    "pages.another-action": "Another action",
    "pages.actions": "ACTIONS",
    "pages.header": "Header",
    "pages.details": "DETAILS",
    "pages.orders": "ORDERS",
    "pages.rating": "Rating",
    "pages.price": "Price",
    "pages.ingredients": "Ingredients",
    "pages.is-vegan": "Is Vegan",
    "pages.order-status": "Order Status",
    "pages.bake-progress": "Bake Progress",
    "pages.popularity": "Popularity",
    "pages.comments": "Comments",
    "pages.mailing-info":
        "Mailing templates use inline styling and table layout to be displayed well at various service providers. To provide better usability we include it as regular html with dangerouslySetInnerHTML.",
    "pages.invoice-info":
        "Invoice template has an inline styled version for usage outside of the project as well as React version.",
    "pages.react-version": "React Version",
    "pages.inline-version": "Inline Styled Html Version",
    "pages.like": "Like",
    "pages.likes": "Likes",
    "pages.details-title": "Details",
    "pages.comments-title": "Comments",
    "pages.questions-title": "Questions",
    "pages.similar-projects": "Similar Projects",
    "pages.send": "Send",
    "pages.addComment": "Add a comment",
    "pages.location": "Location",
    "pages.responsibilities": "Responsibilities",
    "pages.photos": "Photos",
    "pages.who-to-follow": "Who to Follow",
    "pages.follow": "FOLLOW",
    "pages.followers": "FOLLOWERS",
    "pages.recent-posts": "Recent Posts",
    "pages.profile": "PROFILE",
    "pages.friends": "FRIENDS",
    "pages.images": "IMAGES",
    "pages.purchase": "PURCHASE",
    "pages.price.developer": "DEVELOPER",
    "pages.price.team": "TEAM",
    "pages.price.enterprise": "ENTERPRISE",
    "pages.price.twofactorauthentication": "Two factor authentication",
    "pages.price.teampermissions": "Team permissions",
    "pages.price.245Support": "24/5 Support",
    "pages.price.247Support": "24/7 Support",
    "pages.price.useractionsauditlog": "User actions audit log",
    "pages.prices.featurecomparison": "Feature Comparison",
    "pages.prices.pricecomparison": "Price Comparison",

    /* 06.Applications */

    /* 06.01.Chat */
    "chat.messages": "Messages",
    "chat.contacts": "Contacts",
    "chat.saysomething": "Say something..",

    /* 06.02.Survey */
    "survey.delete": "Delete",
    "survey.edit": "Edit",
    "survey.add-new": "ADD NEW",
    "survey.add-new-title": "Add New Survey",
    "survey.title": "Title",
    "survey.category": "Category",
    "survey.label": "Label",
    "survey.status": "Status",
    "survey.cancel": "Cancel",
    "survey.submit": "Submit",
    "survey.another-action": "Another action",
    "survey.display-options": "Display Options",
    "survey.orderby": "Order By : ",
    "survey.all-surveys": "All Surveys",
    "survey.completed-surveys": "Completed Surveys",
    "survey.categories": "Categories",
    "survey.active-surveys": "Active Surveys",
    "survey.labels": "Labels",

    /* 06.03.Todo */
    "todo.add-new": "ADD NEW",
    "todo.add-new-title": "Add New Todo",
    "todo.title": "Title",
    "todo.detail": "Detail",
    "todo.category": "Category",
    "todo.label": "Label",
    "todo.status": "Status",
    "todo.cancel": "Cancel",
    "todo.submit": "Submit",
    "todo.action": "Action",
    "todo.another-action": "Another action",
    "todo.display-options": "Display Options",
    "todo.orderby": "Order By : ",
    "todo.all-tasks": "All Tasks",
    "todo.pending-tasks": "Pending Tasks",
    "todo.completed-tasks": "Completed Tasks",
    "todo.categories": "Categories",
    "todo.labels": "Labels",

    /* 07.UI */

    /* 07.01.Alerts */
    "alert.rounded": "Rounded Alert",
    "alert.react-notifications": "React Notifications",
    "alert.outline": "Outline",
    "alert.primary": "Primary",
    "alert.secondary": "Secondary",
    "alert.info": "Info",
    "alert.success": "Success",
    "alert.warning": "Warning",
    "alert.error": "Error",
    "alert.filled": "Filled",
    "alert.primary-text": "This is a primary alert—check it out!",
    "alert.secondary-text": "This is a secondary alert—check it out!",
    "alert.success-text": "This is a success alert—check it out!",
    "alert.danger-text": "This is a danger alert—check it out!",
    "alert.warning-text": "This is a warning alert—check it out!",
    "alert.info-text": "This is a info alert—check it out!",
    "alert.light-text": "This is a light alert—check it out!",
    "alert.dark-text": "This is a dark alert—check it out!",
    "alert.default": "Default Alert",
    "alert.dismissing": "Dismissing Alert",
    "alert.dismissing-text":
        "Holy guacamole! You should check in on some of those fields below.",
    "alert.dismissing-without-animate-text":
        "I am an alert and I can be dismissed without animating!",

    /* 07.02.Badges */
    "badge.sizes": "Sizes",
    "badge.colors": "Colors",
    "badge.outline": "Outline",
    "badge.links": "Links",
    "badge.counter-badges": "Counter Badges",
    "badge.bootstrap-default": "Bootstrap Default",
    "badge.primary": "Primary",
    "badge.secondary": "Secondary",
    "badge.success": "Success",
    "badge.danger": "Danger",
    "badge.warning": "Warning",
    "badge.info": "Info",
    "badge.light": "Light",
    "badge.dark": "Dark",

    /* 07.03.Buttons */
    "button.default": "Bootstrap Default",
    "button.colors": "Colors",
    "button.rounded": "Rounded",
    "button.outline": "Outline",
    "button.states": "States",
    "button.sizes": "Sizes",
    "button.button-groups": "Button Groups",
    "button.large-button": "Large Button",
    "button.small-button": "Small Button",
    "button.extra-small-button": "Extra Small Button",
    "button.block-button": "Block Button",
    "button.active": "Active",
    "button.disabled": "Disabled",
    "button.basic": "Basic",
    "button.toolbar": "Toolbar",
    "button.nesting": "Nesting",
    "button.vertical-variation": "Vertical Variation",
    "button.checkbox-radio-button": "Checkbox and Radio Button",
    "button.checkbox": "Checkbox",
    "button.radio": "Radio",
    "button.radio-button": "Radio Button",
    "button.primary": "Primary",
    "button.secondary": "Secondary",
    "button.success": "Success",
    "button.danger": "Danger",
    "button.warning": "Warning",
    "button.info": "Info",
    "button.light": "Light",
    "button.dark": "Dark",
    "button.states-text":
        "This button shows a spinner for 2 seconds and an error icon for 3 seconds before switching to normal state. This states can be triggered by adding and removing classes.",
    "button.click-here": "Click Here",
    "button.states-text-alternate":
        "This button shows a spinner for 2 seconds and an error icon for 3 seconds before switching to normal state. This states can be triggered by adding and removing classes.",
    "button.primary-link": "Primary Link",
    "button.link": "Link",
    "button.primary-button": "Primary Button",
    "button.button": "Button",
    "button.left": "Left",
    "button.middle": "Middle",
    "button.right": "Right",
    "button.dropdown": "Dropdown",
    "button.dropdown-link": "Dropdown Link",

    /* 07.04.Cards */
    "cards.icon-card": "Icon Card",
    "cards.image-card": "Image Card",
    "cards.image-overlay-card": "Image Overlay Card",
    "cards.image-card-list": "Image Card List",
    "cards.tab-card": "Tab Card",
    "cards.user-card": "User Card",

    /* 07.05.Carousel */
    "carousel.basic": "Carousel Basic",
    "carousel.single": "Carousel Single",
    "carousel.without-controls": "Carousel Without Controls",

    /* 07.06.Charts */
    "charts.line": "Line Chart",
    "charts.polar": "Polar Chart",
    "charts.area": "Area Chart",
    "charts.scatter": "Scatter Chart",
    "charts.bar": "Bar Chart",
    "charts.radar": "Radar Chart",
    "charts.pie": "Pie Chart",
    "charts.doughnut": "Doughnut Chart",
    "charts.shadow": "Shadow",
    "charts.no-shadow": "No Shadow",

    /* 07.07.Collapse */
    "collapse.basic": "Basic",
    "collapse.toggle": "Toggle",
    "collapse.accordion": "Accordion",
    "collapse.controlled": "Controlled",
    "collapse.uncontrolled": "Uncontrolled",

    /* 07.08.Dropdowns */
    "dropdowns.basic": "Basic",
    "dropdowns.controlled": "Controlled",
    "dropdowns.uncontrolled": "Uncontrolled",
    "dropdowns.dropdown": "Dropdown",
    "dropdowns.header": "Header",
    "dropdowns.action": "Action",
    "dropdowns.another-action": "Another Action",
    "dropdowns.right": "Right",
    "dropdowns.left": "Left",
    "dropdowns.drop-directions": "Drop Directions",
    "dropdowns.dropright": "Dropright",
    "dropdowns.dropleft": "Dropleft",
    "dropdowns.small-button": "Small Button",
    "dropdowns.large-button": "Large Button",
    "dropdowns.sizing": "Sizing",
    "dropdowns.split-button": "Split Button Dropdowns",
    "dropdowns.dropup": "Dropup",

    /* 07.09.Editors */
    "editors.draftjs": "Draft.js",
    "editors.quill-standart": "Quill Standard",
    "editors.quill-bubble": "Quill Bubble",

    /* 07.10.Forms */
    "forms.basic": "Basic",
    "forms.email": "E-mail",
    "forms.email-muted": "We'll never share your email with anyone else.",
    "forms.password": "Password",
    "forms.password-confirm": "Confirm Password",
    "forms.submit": "Submit",
    "forms.horizontal": "Horizontal",
    "forms.radios": "Radios",
    "forms.first-radio": "First radio",
    "forms.second-radio": "Second radio",
    "forms.third-radio-disabled": "Third disabled radio",
    "forms.checkbox": "Checkbox",
    "forms.signin": "Sign in",
    "forms.top-labels-over-line": "Top Labels Over Line",
    "forms.tags": "Tags",
    "forms.date": "Date",
    "forms.state": "State",
    "forms.top-labels-in-input": "Top Labels In Input",
    "forms.email-u": "E-MAIL",
    "forms.password-u": "PASSWORD",
    "forms.tags-u": "TAGS",
    "forms.date-u": "DATE",
    "forms.state-u": "STATE",
    "forms.grid": "Form Grid",
    "forms.address": "Address",
    "forms.address2": "Address 2",
    "forms.city": "City",
    "forms.city-message": "Please select a city!",
    "forms.state-message": "Please select a state!",
    "forms.zip": "Zip",
    "forms.signup": "Signup",
    "forms.inline": "Inline",
    "forms.validation-availity": "Availity Reactstrap Validation",
    "forms.validation-formik": "Formik Validation",
    "forms.default": "Default",
    "forms.firstname": "First name",
    "forms.firstname-message": "Please enter your first name!",
    "forms.lastname": "Last name",
    "forms.lastname-message": "Please enter your last name!",
    "forms.name": "Name",

    /* 07.11.Form Components */
    "form-components.custom-inputs": "Custom Inputs",
    "form-components.checkboxes": "Checkboxes",
    "form-components.radios": "Radios",
    "form-components.inline": "Inline",
    "form-components.react-select": "React Select",
    "form-components.state-single": "State Single",
    "form-components.state-multiple": "State Multiple",
    "form-components.react-autosuggest": "React Autosuggest",
    "form-components.date-picker": "Date Picker",
    "form-components.date": "Date",
    "form-components.date-range": "Date Range",
    "form-components.date-with-time": "Date with Time",
    "form-components.embedded": "Embedded",
    "form-components.dropzone": "Dropzone",
    "form-components.drop-files-here": "Drop Files Here",
    "form-components.tags": "Tags",
    "form-components.switch": "Switch",
    "form-components.primary": "Primary",
    "form-components.secondary": "Secondary",
    "form-components.primary-inverse": "Primary Inverse",
    "form-components.secondary-inverse": "Secondary Inverse",
    "form-components.slider": "Slider",
    "form-components.double-slider": "Double Slider",
    "form-components.single-slider": "Single Slider",
    "form-components.rating": "Rating",
    "form-components.interactive": "Interactive",
    "form-components.readonly": "Readonly",
    "form-components.type-a-cake": "Type a Cake",
    "form-components.start": "Start",
    "form-components.end": "End",
    "form-components.tables": "Tables",
    /* 07.12.Icons */
    "icons.simplelineicons": "Simple Line Icons",
    "icons.iconsmind": "Iconsmind Icons",

    /* 07.13.Input Groups */
    "input-groups.basic": "Basic",
    "input-groups.sizing": "Sizing",
    "input-groups.small": "Small",
    "input-groups.default": "Default",
    "input-groups.large": "Large",
    "input-groups.checkboxes-and-radios": "Checkboxes and radios",
    "input-groups.multiple-inputs": "Multiple Inputs",
    "input-groups.first-and-last-name": "First and last name",
    "input-groups.multiple-addons": "Multiple Addons",
    "input-groups.button-addons": "Button Addons",
    "input-groups.button": "Button",
    "input-groups.buttons-with-dropdowns": "Buttons with Dropdowns",
    "input-groups.dropdown": "Dropdown",
    "input-groups.header": "Header",
    "input-groups.action": "Action",
    "input-groups.another-action": "Another Action",
    "input-groups.custom-select": "Custom Select",
    "input-groups.options": "Options",
    "input-groups.choose": "Choose...",
    "input-groups.custom-file-input": "Custom File Input",

    /* 07.14.Jumbotron */
    "jumbotron.hello-world": "Hello, world!",
    "jumbotron.lead":
        "This is a simple hero unit, a simple jumbotron-style component for calling extra attention to featured content or information.",
    "jumbotron.lead-detail":
        "It uses utility classes for typography and spacing to space content out within the larger container.",
    "jumbotron.learn-more": "Learn more",

    /* 07.15.Modal */
    "modal.basic": "Basic",
    "modal.modal-title": "Modal title",
    "modal.launch-demo-modal": "Launch Demo Modal",
    "modal.scrolling-long-content": "Scrolling Long Content",
    "modal.backdrop": "Backdrop",
    "modal.backdrop-value": "Backdrop value",
    "modal.right-modal": "Right Modal",
    "modal.launch-right-modal": "Launch Right Modal",
    "modal.nested-modal": "Nested Modal",
    "modal.size": "Size",
    "modal.launch-large-modal": "Launch Large Modal",
    "modal.launch-small-modal": "Launch Small Modal",

    /* 07.16.Navigation */
    "nav.basic": "Nav Basic",
    "nav.active": "Active",
    "nav.disabled": "Disabled",
    "nav.disabled-link": "Disabled Link",
    "nav.link": "Link",
    "nav.longer-link": "Longer nav link",
    "nav.another-link": "Another Link",
    "nav.right": "Right",
    "nav.dropdown": "Dropdown",
    "nav.header": "Header",
    "nav.action": "Action",
    "nav.another-action": "Another Action",
    "nav.horizontal-alignment": "Nav Horizontal Alignment",
    "nav.vertical-alignment": "Nav Vertical Alignment",
    "nav.pills": "Nav Pills",
    "nav.fill-justify": "Nav Fill and Justify",
    "nav.pills-dropdowns": "Nav Pills with Dropdowns",
    "nav.pagination-basic": "Pagination Basic",
    "nav.pagination-sizing": "Pagination Sizing",
    "nav.large": "Large",
    "nav.small": "Small",
    "nav.center": "Center",
    "nav.pagination-alignment": "Pagination Alignment",
    "nav.breadcrumb-basic": "Breadcrumb Basic",

    /* 07.17.Popover & Tooltip */
    "popover-tooltip.popover": "Popover",
    "popover-tooltip.tooltip": "Tooltip",

    /* 07.18.Sortable */
    "sortable.columns": "Sorting Columns",
    "sortable.basic": "Basic",
    "sortable.handles": "Handles",

    /* 07.19.Maps */
    "maps.google": "Google",
    "maps.yandex": "Yandex",

    /* 07.20.Tables */
    "table.bootstrap-tables": "Bootstrap Tables",
    "table.bootstrap-basic": "Basic Table",
    "table.bootstrap-striped": "Striped Rows",
    "table.bootstrap-bordered": "Bordered Table",
    "table.bootstrap-borderless": "Borderless Table",
    "table.bootstrap-hoverable": "Hoverable Rows",
    "table.bootstrap-responsive": "Responsive Table",
    "table.react-tables": "React Tables",
    "table.react-pagination": "Pagination",
    "table.react-scrollable": "Scrollable",
    "table.react-advanced": "Filter, Length and Jump",

    /* 07.21.Wizards */
    "wizard.step-name-1": "Step 1",
    "wizard.step-name-2": "Step 2",
    "wizard.step-name-3": "Step 3",
    "wizard.step-desc-1": "First step description",
    "wizard.step-desc-2": "Second step description",
    "wizard.step-desc-3": "Third step description",
    "wizard.content-1": "Step content for first step.",
    "wizard.content-2": "Step content for second step.",
    "wizard.content-3": "Last step content!",
    "wizard.content-thanks": "Thank You!",
    "wizard.next": "Next",
    "wizard.prev": "Back",
    "wizard.registered": "Your registration completed successfully!",
    "wizard.async": "Async save for 3 seconds!",
};
