
export const GET_NOSOTROS_LOGIN = '[NOSOTROS_LOGIN] SET NOSOTROS_LOGIN';
export const GET_NOSOTROS_LOGIN_SUCCESS = '[NOSOTROS_LOGIN] SET NOSOTROS_LOGIN SUCCESS';
export const GET_NOSOTROS_LOGIN_ERROR = '[NOSOTROS_LOGIN] SET NOSOTROS_LOGIN ERROR';


export const getNosotrosLogin = () => ({
    type: GET_NOSOTROS_LOGIN,
});
export const getNosotrosLoginSuccess = (payload) => ({
    type: GET_NOSOTROS_LOGIN_SUCCESS,
    payload
});
export const getNosotrosLoginError = (payload) => ({
    type: GET_NOSOTROS_LOGIN_ERROR,
    payload
});
