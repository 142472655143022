export const GET_MONEDA = "[MONEDA] GET MONEDA";
export const GET_MONEDA_SUCCESS = "[MONEDA] GET MONEDA SUCCESS";
export const GET_MONEDA_ERROR = "[MONEDA] GET MONEDA ERROR";

export const UPDATE_MONEDA = "[MONEDA] UPDATE MONEDA";
export const UPDATE_MONEDA_SUCCESS = "[MONEDA] UPDATE MONEDA SUCCESS";
export const UPDATE_MONEDA_ERROR = "[MONEDA] UPDATE MONEDA ERROR";

export const OPEN_DIALOG = "[MONEDA] INMUEBLE OPEN NEW DIALOG";
export const CLOSE_DIALOG = "[MONEDA] INMUEBLE CLOSE NEW DIALOG";

export const getMoneda = () => ({
    type: GET_MONEDA,
});
export const getMonedaSuccess = (payload) => ({
    type: GET_MONEDA_SUCCESS,
    payload,
});
export const getMonedaError = (payload) => ({
    type: GET_MONEDA_ERROR,
    payload,
});

export const updateMoneda = (payload) => ({
    type: UPDATE_MONEDA,
    payload,
});
export const updateMonedaSuccess = (id, form) => ({
    type: UPDATE_MONEDA_SUCCESS,
    id,
    form,
});
export const updateMonedaError = (payload) => ({
    type: UPDATE_MONEDA_ERROR,
    payload,
});

export function openDialog() {
    return {
        type: OPEN_DIALOG,
    };
}

export function closeDialog() {
    return {
        type: CLOSE_DIALOG,
    };
}
