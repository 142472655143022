module.exports = {
    "servicios.tratamiento-capilar-titulo": "new services Tratamiento Capilar",
    "servicios.tratamiento-capilar-input-personal": "S P",
    "servicios.tratamiento-capilar-input-tipo-cabello": "T C",
    "servicios.tratamiento-capilar-input-grado-porosidad": "G P",
    "servicios.tratamiento-capilar-input-largo-cabello": "L C",
    "servicios.tratamiento-capilar-input-corte-elegido": "T S",
    "servicios.tratamiento-capilar-input-venta": "V",
    "servicios.tratamiento-capilar-input-precio": "P Bs",
};
