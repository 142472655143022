module.exports = {
    "menu.menu-estadistica": "Start Customer",
    "menu.estadistica": "Customer",

    "menu.sidebar.estadistica": "Customer",

    "estadistica.numero-cliente": "Número de Clientes",
    "estadistica.numero-personal": "Número de Personal",
    "estadistica.numero-usuario": "Número de Usuarios",
    "estadistica.ganancia-semanal": "Ganancia Semanal",
    "estadistica.ganancia-mes": "Ganancia Mensual",
    "estadistica.ganancia-anio": "Ganancia Anual",

    "cliente.tabla-nombre": "Name",
    "cliente.tabla-paterno": "Pather Last Name",
    "cliente.tabla-materno": "Mother Last Name",
    "cliente.tabla-correo": "Email",
    "cliente.tabla-celular": "Cell Phone",
    "cliente.tabla-genero": "Gender",
    "cliente.tabla-fecha-nacimiento": "Happy Day",
};
