import { lazy, Suspense, useEffect, useCallback, Fragment } from "react";
// import {
//     // BrowserRouter as Router,
//     Route,
//     Switch,
//     // Redirect,
// } from "react-router-dom";
import { useSelector } from "react-redux";

import { Router, Redirect } from "@reach/router";

import Auth from "constants/Auth";
import AuthWeb from "constants/AuthWeb";
import { IntlProvider } from "react-intl";
// import "helpers/Firebase";
import AppLocale from "lang";
import ColorSwitcher from "components/common/ColorSwitcher";
import NotificationContainer from "components/common/react-notifications/NotificationContainer";
import { isMultiColorActive } from "constants/defaultValues";
import { getDirection } from "helpers/Utils";

import jwtService from "services/jwtService";
import initStore from "redux/store";

import rootSagaGeneralApp from "views/app/store/saga";
import rootSagaGeneralWeb from "views/web/store/saga";
import rootSagaGeneralUser from "views/user/store/saga";
// import rootSagaAuthUser from "redux/auth/saga";

const store = initStore();

// const ViewMain = lazy(() =>
//     import(/* webpackChunkName: "views" */ "views")
// );

// // const ViewApp = lazy(() =>
// //     import(/* webpackChunkName: "views-app" */ "views/app")
// // );
const ViewApp = lazy(() =>
    import("views/app/store/reducers").then((module) => {
        store.injectReducer("generalApp", module.default);
        store.injectSaga("generalApp", rootSagaGeneralApp);
        return import(/* webpackChunkName: "views-app" */ "views/app");
    })
);

const ViewUser = lazy(() =>
    import("views/user/store/reducers").then((module) => {
        store.injectReducer("GeneralUserApp", module.default);
        store.injectSaga("GeneralUserApp", rootSagaGeneralUser);
        return import(/* webpackChunkName: "views-user" */ "views/user");
    })
);
// // const ViewUser = lazy(() =>
// //     // import(/* webpackChunkName: "viwes-second-menu" */ "./contact")
// //     import("./redux/auth/reducer").then((module) => {
// //         store.injectReducer("authUser", module.default);
// //         store.injectSaga("authUser", rootSagaAuthUser);
// //         return import(/* webpackChunkName: "pagina1" */ "views/user");
// //     })
// // );
const ViewError = lazy(() =>
    import(/* webpackChunkName: "views-error" */ "views/error")
);
// // const ViewWeb = lazy(() =>
// //     import(/* webpackChunkName: "views-app" */ "views/web")
// // );
const ViewWeb = lazy(() =>
    // import(/* webpackChunkName: "viwes-second-menu" */ "./contact")
    import("views/web/store/reducers").then((module) => {
        store.injectReducer("generalWebApp", module.default);
        store.injectSaga("generalWebApp", rootSagaGeneralWeb);
        return import(/* webpackChunkName: "views-web-page" */ "views/web");
    })
);

// const AuthRoute = ({ component: Component, ...rest }) => {
const AuthRoute = () => {
    return jwtService.getAccessToken() ? (
        <Auth>
            <Suspense
                fallback={
                    <div style={{ height: "100vh" }}>
                        <div className="loading" />
                    </div>
                }
            >
                <Router>
                    <ViewApp path={`/*`} />
                </Router>
            </Suspense>
        </Auth>
    ) : (
        <Redirect from="/" to="/user" noThrow />
    );
    // return (
    //     <Route
    //         {...rest}
    //         render={(props) =>
    //             jwtService.getAccessToken() ? (
    //                 <Auth>
    //                     <Component {...props} />
    //                 </Auth>
    //             ) : (
    //                 <Redirect
    //                     to={{
    //                         pathname: "/user/login",
    //                         state: { from: props.location },
    //                     }}
    //                 />
    //             )
    //         }
    //     />
    // );
};
const AuthRouteWeb = () => {
    return (
        <AuthWeb>
            <Suspense
                fallback={
                    <div style={{ height: "100vh" }}>
                        <div className="loading" />
                    </div>
                }
            >
                <Router>
                    <ViewWeb path={`/*`} />
                </Router>
            </Suspense>
        </AuthWeb>
    );
};
// const AuthRouteWeb = ({ component: Component, ...rest }) => {
//     return (
//         <Route
//             {...rest}
//             render={(props) =>
//                 <AuthWeb>
//                     <Component {...props} />
//                 </AuthWeb>
//             }
//         />
//     );
// };
const AuthRouteUser = () => {
    return (
        <AuthWeb>
            <Suspense
                fallback={
                    <div style={{ height: "100vh" }}>
                        <div className="loading" />
                    </div>
                }
            >
                <Router>
                    <ViewUser path={`/*`} />
                </Router>
            </Suspense>
        </AuthWeb>
    );
    // return true ? (
    //         <Router>
    //             {/* <Redirect from={`${rest.path}/`} to={`${rest.path}/login`} /> */}
    //             {/* <AuthWeb path={`${rest.path}/*`}> */}
    //                 <ViewUser path={`user/*`}/>
    //             {/* </AuthWeb> */}
    //         </Router>
    // ) : (
    //     <Router>
    //         <Redirect from={`/`} to={`/`} />
    //     </Router>
    // );
    // return (
    //     <Route
    //         {...rest}
    //         render={(props) =>
    //             // !jwtService.getAccessToken() ? (
    //             true ? (
    //                 <AuthWeb>
    //                     <Component {...props} />
    //                 </AuthWeb>
    //             ) : (
    //                 <Redirect
    //                     to={{
    //                         pathname: "/app/perfil",
    //                         state: { from: props.location },
    //                     }}
    //                 />
    //             )
    //         }
    //     />
    // );
};

const App = () => {
    const settings = useSelector(({ settings }) => settings);
    // const authUser = useSelector(({ authUser }) => authUser);

    const direction = getDirection();

    const initDatos = useCallback(() => {
        if (direction.isRtl) {
            document.body.classList.add("rtl");
            document.body.classList.remove("ltr");
        }
    }, [direction]);

    useEffect(() => {
        initDatos();
    }, [initDatos]);

    const { locale } = settings;
    // const { user } = authUser;
    const currentAppLocale = AppLocale[locale];

    return (
        <IntlProvider
            locale={locale}
            messages={currentAppLocale.messages}
            defaultLocale="es"
        >
            <Fragment>
                <NotificationContainer />
                {isMultiColorActive && <ColorSwitcher />}
                <Suspense
                    fallback={
                        <div style={{ height: "100vh" }}>
                            <div className="loading" />
                        </div>
                    }
                >
                    <Router>
                        <Redirect from="/" to="web" noThrow />
                        <ViewError default />
                        <AuthRoute path="app/*" />
                        <AuthRouteUser path="user/*" />
                        <AuthRouteWeb path="web/*" />
                    </Router>
                </Suspense>
                {/* <Suspense fallback={<div style={{height: '100vh'}}><div className="loading" /></div>}>
                        <Router>
                            <Switch>
                                <AuthRoute
                                    path="/app"
                                    authUser={user}
                                    component={ViewApp}
                                />
                                <AuthRouteUser
                                    path="/user"
                                    component={ViewUser}
                                />
                                <AuthRouteWeb
                                    path="/web"
                                    component={ViewWeb}
                                />
                                <Route
                                    path="/error"
                                    exact
                                    render={(props) => (
                                        <ViewError {...props} />
                                    )}
                                />
                                <Route
                                    path="/"
                                    exact
                                    render={(props) => (
                                        <ViewMain {...props} />
                                    )}
                                />
                                <Redirect to="/error" />
                            </Switch>
                        </Router>
                    </Suspense> */}
            </Fragment>
        </IntlProvider>
    );
};

export default App;
