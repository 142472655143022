import { Component, Fragment } from "react";
import { connect } from "react-redux";
import * as userActions from "redux/auth/actions";
import { bindActionCreators } from "redux";
// import * as Actions from 'app/store/actions';
import jwtService from "services/jwtService";
import history from "@tteenbe/@history";

class AuthWeb extends Component {
    /*eslint-disable-next-line no-useless-constructor*/
    constructor(props) {
        super(props);
        this.jwtCheck();
    }

    jwtCheck = () => {
        jwtService.on("onAutoLogin", () => {
            jwtService
                .signInWithToken()
                .then((user) => {
                    this.props.setUserData(user);
                })
                .catch((error) => {
                    console.warn(error);
                    // this.props.logout();
                    // this.props.showMessage({message: error});
                });
        });

        jwtService.on("onAutoLogout", (message) => {
            if (message) {
                // this.props.showMessage({message});
            }
            // localStorage.removeItem('user_id');
            this.props.logout(history);
        });

        jwtService.init();
    };

    render() {
        const { children } = this.props;
        return <Fragment>{children}</Fragment>;
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            logout: userActions.logoutUserSuccess,
            setUserData: userActions.setUserData,
            // setUserDataAuth0   : userActions.setUserDataAuth0,
            // setUserDataFirebase: userActions.setUserDataFirebase,
            // showMessage        : Actions.showMessage,
            // hideMessage        : Actions.hideMessage
        },
        dispatch
    );
}

export default connect(null, mapDispatchToProps)(AuthWeb);
