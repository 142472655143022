module.exports = {
    "menu.menu-usuario": "Usuario Inicio",
    "menu.usuario": "Usuario",
    "menu.menu-usuarios": "Usuarios Inicio",
    "menu.usuarios": "Usuarios",

    "menu.sidebar.usuario": "Usuario",
    "menu.sidebar.usuarios": "Usuarios",

    
    "usuario.crud-c": "Nuevo Usuario",
    "usuario.crud-u": "Editar Usario",
    "usuario.crud-d": "Eliminar Usuario",
    "usuario.tabla-nombre": "Nombre",
    "usuario.tabla-paterno": "Paterno",
    "usuario.tabla-materno": "Materno",
    "usuario.tabla-correo": "Correo",
    "usuario.tabla-celular": "Celular",
    "usuario.tabla-genero": "Genero",
    "usuario.tabla-id_roles": "Rol",
    "usuario.tabla-password": "Password",

};
