module.exports = {
    "menu.menu-cliente": "Start Customer",
    "menu.cliente": "Customer",

    "menu.sidebar.cliente": "Customer",

    "cliente.crud-c": "New Customer",
    "cliente.crud-u": "Update Customer",
    "cliente.crud-d": "Delete Customer",

    "cliente.tabla-nombre": "Name",
    "cliente.tabla-paterno": "Pather Last Name",
    "cliente.tabla-materno": "Mother Last Name",
    "cliente.tabla-correo": "Email",
    "cliente.tabla-celular": "Cell Phone",
    "cliente.tabla-genero": "Gender",
    "cliente.tabla-fecha-nacimiento": "Happy Day",
    "cliente.tabla-edad-aproximada": "Edad Aproximada",
};
