module.exports = {
    "menu.sidebar.personal": "Personal",
    "menu.menu-personal": "Start Personal",
    "menu.personal": "Personal",

    "personal.crud-c": "Nuevo Personal",
    "personal.crud-u": "Editar Personal",
    "personal.crud-d": "Eliminar Personal",

    "personal.tabla-nombre": "Nombre",
    "personal.tabla-paterno": "Paterno",
    "personal.tabla-materno": "Materno",
    "personal.tabla-correo": "Correo",
    "personal.tabla-celular": "Celular",
    "personal.tabla-cedula": "Carnet de Identidad",
    "personal.tabla-genero": "Genero",
    "personal.tabla-fecha-nacimiento": "Fecha Nacimiento",
};
