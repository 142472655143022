module.exports = {
    "servicios.tintes-titulo": "Servicio de Tintes",
    "servicios.tintes-input-personal": "Seleccionar Personal",
    "servicios.tintes-input-cn": "CN",
    "servicios.tintes-input-cslyp": "CSLYP",
    "servicios.tintes-input-porcentaje-camas": "Porcentaje Camas",
    "servicios.tintes-input-restos-decoloracion": "Restos Decoloracion",
    "servicios.tintes-input-cd": "CD",
    "servicios.tintes-input-mezcla-trabajo": "Formulación",
    "servicios.tintes-input-trabajo": "Trabajo",
    "servicios.tintes-input-venta": "Venta",
    "servicios.tintes-input-precio": "Precio Bs",
};
