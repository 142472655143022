import enMessages from "../locales/en_US";

import enMenuSidebarUsuario from "../locales/administracion/usuario/en_US";
import enMenuSidebarPerfil from "../locales/administracion/perfil/en_US";
import enMenuSidebarCliente from "../locales/administracion/cliente/en_US";
import enMenuSidebarPersonal from "../locales/administracion/personal/en_US";
import enMenuSidebarPromocion from "../locales/administracion/promocion/en_US";
import enMenuSidebarEstadistica from "../locales/administracion/estadistica/en_US";
import enMenuSidebarServicios from "../locales/administracion/servicios/en_US";
import enMenuSidebarHistorial from "../locales/administracion/historial/en_US";
import enMenuSidebarReportes from "../locales/administracion/reportes/en_US";

/* SERVICIOS AGREGAR */
import enServicioCortes from "../locales/administracion/servicios/dialog-servicios/servicio-cortes/en_US";
import enServicioAlizadoPermanente from "../locales/administracion/servicios/dialog-servicios/servicio-alizado-permanente/en_US";
import enServicioBanioLuna from "../locales/administracion/servicios/dialog-servicios/servicio-banio-luna/en_US";
import enServicioBase from "../locales/administracion/servicios/dialog-servicios/servicio-base/en_US";
import enServicioDaySpa from "../locales/administracion/servicios/dialog-servicios/servicio-day-spa/en_US";
import enServicioDepilacion from "../locales/administracion/servicios/dialog-servicios/servicio-depilacion/en_US";
import enServicioExfoliacion from "../locales/administracion/servicios/dialog-servicios/servicio-exfoliacion/en_US";
import enServicioFacial from "../locales/administracion/servicios/dialog-servicios/servicio-facial/en_US";
import enServicioMano from "../locales/administracion/servicios/dialog-servicios/servicio-mano/en_US";
import enServicioMaquillaje from "../locales/administracion/servicios/dialog-servicios/servicio-maquillaje/en_US";
import enServicioNovias from "../locales/administracion/servicios/dialog-servicios/servicio-novias/en_US";
import enServicioPeinado from "../locales/administracion/servicios/dialog-servicios/servicio-peinado/en_US";
import enServicioQuinceAnios from "../locales/administracion/servicios/dialog-servicios/servicio-quince-anios/en_US";
import enServicioReductora from "../locales/administracion/servicios/dialog-servicios/servicio-reductora/en_US";
import enServicioRelax from "../locales/administracion/servicios/dialog-servicios/servicio-relax/en_US";
import enServicioTintes from "../locales/administracion/servicios/dialog-servicios/servicio-tintes/en_US";
import enServicioTratamientoCapilar from "../locales/administracion/servicios/dialog-servicios/servicio-tratamiento-capilar/en_US";

const EnLang = {
    messages: {
        ...enMessages,
        ...enMenuSidebarUsuario,
        ...enMenuSidebarPerfil,
        ...enMenuSidebarCliente,
        ...enMenuSidebarPromocion,
        ...enMenuSidebarPersonal,
        ...enMenuSidebarEstadistica,
        ...enMenuSidebarServicios,
        ...enMenuSidebarHistorial,
        ...enMenuSidebarReportes,
        /* SERVICIOS AGREGAR */
        ...enServicioCortes,
        ...enServicioAlizadoPermanente,
        ...enServicioBanioLuna,
        ...enServicioBase,
        ...enServicioDaySpa,
        ...enServicioDepilacion,
        ...enServicioExfoliacion,
        ...enServicioFacial,
        ...enServicioMano,
        ...enServicioMaquillaje,
        ...enServicioNovias,
        ...enServicioPeinado,
        ...enServicioQuinceAnios,
        ...enServicioReductora,
        ...enServicioRelax,
        ...enServicioTintes,
        ...enServicioTratamientoCapilar,
    },
    locale: "en-US",
};
export default EnLang;
