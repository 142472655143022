module.exports = {
    "servicios.cortes-titulo": "new services cut",
    "servicios.cortes-input-personal": "S P",
    "servicios.cortes-input-tipo-cabello": "T C",
    "servicios.cortes-input-grado-porosidad": "G P",
    "servicios.cortes-input-largo-cabello": "L C",
    "servicios.cortes-input-corte-elegido": "C E",
    "servicios.cortes-input-venta": "V",
    "servicios.cortes-input-precio": "P Bs",
};
