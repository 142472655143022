export const GET_CONTACTO_GENERAL_MENU_FOOTER =
    "[MENU FOTTER CONTACTO GENERAL] GET CONTACTO GENERAL MENU FOOTER";
export const GET_CONTACTO_GENERAL_MENU_FOOTER_SUCCESS =
    "[MENU FOTTER CONTACTO GENERAL] GET CONTACTO GENERAL MENU FOOTER SUCCESS";
export const GET_CONTACTO_GENERAL_MENU_FOOTER_ERROR =
    "[MENU FOTTER CONTACTO GENERAL] GET CONTACTO GENERAL MENU FOOTER ERROR";

export const getContactoGeneralMenuFooter = () => ({
    type: GET_CONTACTO_GENERAL_MENU_FOOTER,
});
export const getContactoGeneralMenuFooterSuccess = (payload) => ({
    type: GET_CONTACTO_GENERAL_MENU_FOOTER_SUCCESS,
    payload,
});
export const getContactoGeneralMenuFooterError = (payload) => ({
    type: GET_CONTACTO_GENERAL_MENU_FOOTER_ERROR,
    payload,
});
