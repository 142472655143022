import { useEffect, Fragment } from "react";
import jwtService from "services/jwtService";
import history from "@tteenbe/@history";
import { useDispatch } from "react-redux";

import { logoutUserSuccess, setUserData } from "redux/auth/actions";

const Auth = (props) => {
    const dispatch = useDispatch();
    const { children } = props;

    const jwtCheck = () => {
        jwtService.on("onAutoLogin", () => {
            jwtService
                .signInWithToken()
                .then((user) => {
                    dispatch(setUserData(user));
                })
                .catch((error) => {
                    console.warn(error);
                });
        });

        jwtService.on("onAutoLogout", (message) => {
            console.warn(message);
            if (message) {
                // this.props.showMessage({message});
            }
            dispatch(logoutUserSuccess(history));
        });

        jwtService.init();
    };

    useEffect(() => {
        jwtCheck();
        return () => jwtCheck();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Fragment>{children}</Fragment>;
};

export default Auth;
