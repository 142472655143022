import { takeEvery, fork } from "redux-saga/effects";

import { SET_RUTA } from "../actions/actions_rutas";

export function* watchGoUrl() {
    yield takeEvery(SET_RUTA, setRuta);
}

function* setRuta({ history, siguiente }) {
    try {
        yield history.push(siguiente);
    } catch (error) {
        return;
    }
}

const sagasRutas = [fork(watchGoUrl)];

export default sagasRutas;
