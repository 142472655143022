module.exports = {
    "servicios.cortes-titulo": "Servicio de Cortes",
    "servicios.cortes-input-personal": "Seleccionar Personal",
    "servicios.cortes-input-tipo-cabello": "Tipo Cabello",
    "servicios.cortes-input-grado-porosidad": "Grado Porosidad",
    "servicios.cortes-input-largo-cabello": "Largo Cabello",
    "servicios.cortes-input-corte-elegido": "Corte Elegido",
    "servicios.cortes-input-venta": "Venta",
    "servicios.cortes-input-precio": "Precio Bs",
};
