module.exports = {
    "menu.sidebar.personal": "Employee",
    "menu.menu-personal": "Start Employee",
    "menu.personal": "Employee",

    "personal.crud-c": "New Employee",
    "personal.crud-u": "Update Employee",
    "personal.crud-d": "Delete Employee",

    "personal.tabla-nombre": "Name",
    "personal.tabla-paterno": "Pather Last Name",
    "personal.tabla-materno": "Mother Last Name",
    "personal.tabla-correo": "Email",
    "personal.tabla-celular": "Cell Phone",
    "personal.tabla-cedula": "Carnet de Identidad",
    "personal.tabla-genero": "Gender",
    "personal.tabla-fecha-nacimiento": "Happy Day",
};
