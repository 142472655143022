module.exports = {
    "menu.menu-estadistica": "Estadística Inicio",
    "menu.estadistica": "Estadística",

    "menu.sidebar.estadistica": "Estadística",

    "estadistica.numero-cliente": "Número de Clientes",
    "estadistica.numero-personal": "Número de Personal",
    "estadistica.numero-usuario": "Número de Usuarios",
    "estadistica.ganancia-semanal": "Ganancia Semanal",
    "estadistica.ganancia-mes": "Ganancia Mensual",
    "estadistica.ganancia-anio": "Ganancia Anual",

    "cliente.tabla-nombre": "Nombre",
    "cliente.tabla-paterno": "Paterno",
    "cliente.tabla-materno": "Materno",
    "cliente.tabla-correo": "Correo",
    "cliente.tabla-celular": "Celular",
    "cliente.tabla-genero": "Genero",
    "cliente.tabla-fecha-nacimiento": "Fecha Nacimiento",
};
