module.exports = {
    "servicios.facial-titulo": "new services facial",
    "servicios.facial-input-personal": "S P",
    "servicios.facial-input-textura-piel": "T P",
    "servicios.facial-input-poros": "P",
    "servicios.facial-input-aspecto": "A",
    "servicios.facial-input-impuresa": "I",
    "servicios.facial-input-protocolo": "P",
    "servicios.facial-input-venta": "V",
    "servicios.facial-input-precio": "P Bs",
};
