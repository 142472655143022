import esMessages from "../locales/es_ES";

import esMenuSidebarUsuario from "../locales/administracion/usuario/es_ES";
import esMenuSidebarPerfil from "../locales/administracion/perfil/es_ES";
import esMenuSidebarPromocion from "../locales/administracion/promocion/es_ES";
import esMenuSidebarCliente from "../locales/administracion/cliente/es_ES";
import esMenuSidebarPersonal from "../locales/administracion/personal/es_ES";
import esMenuSidebarEstadistica from "../locales/administracion/estadistica/es_ES";
import esMenuSidebarServicios from "../locales/administracion/servicios/es_ES";
import esMenuSidebarHistorial from "../locales/administracion/historial/es_ES";
import esMenuSidebarReportes from "../locales/administracion/reportes/es_ES";

/* SERVICIOS AGREGAR */
import esServicioCortes from "../locales/administracion/servicios/dialog-servicios/servicio-cortes/es_ES";
import esServicioAlizadoPermanente from "../locales/administracion/servicios/dialog-servicios/servicio-alizado-permanente/es_ES";
import esServicioBanioLuna from "../locales/administracion/servicios/dialog-servicios/servicio-banio-luna/es_ES";
import esServicioBase from "../locales/administracion/servicios/dialog-servicios/servicio-base/es_ES";
import esServicioDaySpa from "../locales/administracion/servicios/dialog-servicios/servicio-day-spa/es_ES";
import esServicioDepilacion from "../locales/administracion/servicios/dialog-servicios/servicio-depilacion/es_ES";
import esServicioExfoliacion from "../locales/administracion/servicios/dialog-servicios/servicio-exfoliacion/es_ES";
import esServicioFacial from "../locales/administracion/servicios/dialog-servicios/servicio-facial/es_ES";
import esServicioMano from "../locales/administracion/servicios/dialog-servicios/servicio-mano/es_ES";
import esServicioMaquillaje from "../locales/administracion/servicios/dialog-servicios/servicio-maquillaje/es_ES";
import esServicioNovias from "../locales/administracion/servicios/dialog-servicios/servicio-novias/es_ES";
import esServicioPeinado from "../locales/administracion/servicios/dialog-servicios/servicio-peinado/es_ES";
import esServicioQuinceAnios from "../locales/administracion/servicios/dialog-servicios/servicio-quince-anios/es_ES";
import esServicioReductora from "../locales/administracion/servicios/dialog-servicios/servicio-reductora/es_ES";
import esServicioRelax from "../locales/administracion/servicios/dialog-servicios/servicio-relax/es_ES";
import esServicioTintes from "../locales/administracion/servicios/dialog-servicios/servicio-tintes/es_ES";
import esServicioTratamientoCapilar from "../locales/administracion/servicios/dialog-servicios/servicio-tratamiento-capilar/es_ES";

const EsLang = {
    messages: {
        ...esMessages,
        ...esMenuSidebarUsuario,
        ...esMenuSidebarPerfil,
        ...esMenuSidebarCliente,
        ...esMenuSidebarPersonal,
        ...esMenuSidebarEstadistica,
        ...esMenuSidebarPromocion,
        ...esMenuSidebarServicios,
        ...esMenuSidebarHistorial,
        ...esMenuSidebarReportes,
        /* SERVICIOS AGREGAR */
        ...esServicioCortes,
        ...esServicioAlizadoPermanente,
        ...esServicioBanioLuna,
        ...esServicioBase,
        ...esServicioDaySpa,
        ...esServicioDepilacion,
        ...esServicioExfoliacion,
        ...esServicioFacial,
        ...esServicioMano,
        ...esServicioMaquillaje,
        ...esServicioNovias,
        ...esServicioPeinado,
        ...esServicioQuinceAnios,
        ...esServicioReductora,
        ...esServicioRelax,
        ...esServicioTintes,
        ...esServicioTratamientoCapilar,
    },
    locale: "es-ES",
};
export default EsLang;
