module.exports = {
    "servicios.alizado-permanente-titulo": "new services Alizado Permanente",
    "servicios.alizado-permanente-input-personal": "S P",
    "servicios.alizado-permanente-input-tipo-cabello": "T C",
    "servicios.alizado-permanente-input-grado-porosidad": "G P",
    "servicios.alizado-permanente-input-largo-cabello": "L C",
    "servicios.alizado-permanente-input-crema-alizante": "C A",
    "servicios.alizado-permanente-input-neutralizante": "N",    
    "servicios.alizado-permanente-input-trabajo-seguir": "T S",
    "servicios.alizado-permanente-input-venta": "V",
    "servicios.alizado-permanente-input-precio": "P Bs",
};
