module.exports = {
    "menu.sidebar.servicios": "Servicios",
    "servicios.titulo": "Servicios",

    /* agregar servicio modal */
    "servicios.seleccionar-cliente": "Seleccionar Cliente",
    "servicios.seleccionar-servicios": "Seleccionar Servicios",
    "servicios.seleccionar-created": "Hora de atencion",

    "servicios.seleccionar-necesario-servicio":
        "Es necesario que seleccione al menos un servicio",
    "servicios.seleccionar-agregar-cliente": "Agregar Cliente",
    "servicios.seleccionar-necesario-cliente":
        "Es necesario que Seleccione al Cliente",
    "servicios.seleccionar-en-atencion-cliente":
        "El Cliente seleccionado esta siendo Atendido",

    /* ventana de servicios */
    "servicios.header-nuestros-servicios": "Nuestros Servicios",
    "servicios.header-cliente": "Cliente",
    "servicios.header-cobrar": "Cobrar",
    "servicios.menu-alizado_permanente": "Alizado Permanente",
    "servicios.menu-banio_luna": "Baño Luna",
    "servicios.menu-base": "Base",
    "servicios.menu-cortes": "Cortes",
    "servicios.menu-day_spa": "Day Spa",
    "servicios.menu-depilacion": "Depilación",
    "servicios.menu-exfoliacion": "Exfoliación",
    "servicios.menu-facial": "Facial",
    "servicios.menu-mano": "Mano",
    "servicios.menu-maquillaje": "Maquillaje",
    "servicios.menu-novias": "Novias",
    "servicios.menu-peinado": "Peinado",
    "servicios.menu-quince_anios": "Quince Años",
    "servicios.menu-reductora": "Reductora",
    "servicios.menu-relax": "Relax",
    "servicios.menu-tintes": "Tintes",
    "servicios.menu-tratamiento_capilar": "Tratamiento Capilar",

    /* menu derecha clientes activos */
    "servicios.button-general": "General Servicios",
    "servicios.button-general-agregar": "Agregar Cliente",
    "servicios.titulo-cliente-activo": "Clientes en Servicios",
    "servicios.cliente.texto-cumpleanios": "Cumpleaños",
    "servicios.cliente.texto-correo": "Correo",
    "servicios.cliente.texto-celular": "Nro. Celular",
};
