module.exports = {
    "servicios.base-titulo": "new services Base",
    "servicios.base-input-personal": "S P",
    "servicios.base-input-tipo-cabello": "T C",
    "servicios.base-input-grado-porosidad": "G P",
    "servicios.base-input-largo-cabello": "L C",
    "servicios.base-input-pre-permanente": "P P",
    "servicios.base-input-neutralizante": "N",
    "servicios.base-input-liquido": "L",
    "servicios.base-input-liquido-permanente": "L P",
    "servicios.base-input-venta": "V",
    "servicios.base-input-precio": "P Bs",
};
