module.exports = {
    "menu.menu-cliente": "Cliente Inicio",
    "menu.cliente": "Cliente",

    "menu.sidebar.cliente": "Cliente",

    "cliente.crud-c": "Nuevo Cliente",
    "cliente.crud-u": "Editar Cliente",
    "cliente.crud-d": "Eliminar Cliente",

    "cliente.tabla-nombre": "Nombre",
    "cliente.tabla-paterno": "Paterno",
    "cliente.tabla-materno": "Materno",
    "cliente.tabla-correo": "Correo",
    "cliente.tabla-celular": "Celular",
    "cliente.tabla-genero": "Genero",
    "cliente.tabla-fecha-nacimiento": "Fecha de Cumpleaños",
    "cliente.tabla-edad-aproximada": "Edad Aproximada",
};
