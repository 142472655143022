import { call, put, takeEvery, fork } from "redux-saga/effects";

import { instance_public } from "../../../../helpers/Instance";

import {
    GET_CONTACTO_GENERAL_MENU_FOOTER,
    getContactoGeneralMenuFooterSuccess,
    getContactoGeneralMenuFooterError,
} from "../actions";

const tabla = "contacto_general";

export function* watchGetContactoGeneralMenuFooter() {
    yield takeEvery(GET_CONTACTO_GENERAL_MENU_FOOTER, GetList);
}

function* GetList() {
    try {
        const { data } = yield call(contactogeneralmenufooterGetAsync);
        if (data?.success)
            yield put(getContactoGeneralMenuFooterSuccess(data.data));
        else yield put(getContactoGeneralMenuFooterError(data.message));
    } catch (error) {
        return;
    }
}

const contactogeneralmenufooterGetAsync = () => instance_public.get(`${tabla}`);

const sagasContactoGeneralMenuFooter = [
    fork(watchGetContactoGeneralMenuFooter),
];

export default sagasContactoGeneralMenuFooter;
