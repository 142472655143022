module.exports = {
    "servicios.tintes-titulo": "new services Tintes",
    "servicios.tintes-input-personal": "S P",
    "servicios.tintes-input-cn": "CN",
    "servicios.tintes-input-cslyp": "CSLYP",
    "servicios.tintes-input-porcentaje-camas": "Porcentaje Camas",
    "servicios.tintes-input-restos-decoloracion": "Restos Decoloracion",
    "servicios.tintes-input-cd": "CD",
    "servicios.tintes-input-mezcla-trabajo": "Formulación",
    "servicios.tintes-input-venta": "V",
    "servicios.tintes-input-precio": "P Bs",
};
