module.exports = {
    "menu.sidebar.servicios": "Services",
    "servicios.titulo": "Services",

    /* agregar servicio modal */
    "servicios.seleccionar-cliente": "Selected Customer",
    "servicios.seleccionar-servicios": "Selected Services",
    "servicios.seleccionar-created": "Hora de atencion",

    "servicios.seleccionar-necesario-servicio":
        "Es necesario que seleccione al menos un servicio",
    "servicios.seleccionar-agregar-cliente": "Agregar Cliente",
    "servicios.seleccionar-necesario-cliente":
        "Es Necesario Que Seleccione Al Cliente",
    "servicios.seleccionar-en-atencion-cliente":
        "El Cliente Seleccionado Esta Siendo Atendido",

    /* ventana de servicios */
    "servicios.header-nuestros-servicios": "We Services",
    "servicios.header-cliente": "Customer",
    "servicios.header-cobrar": "Pay",
    "servicios.menu-alizado_permanente": "a p",
    "servicios.menu-banio_luna": "b l",
    "servicios.menu-base": "b",
    "servicios.menu-cortes": "Cuts",
    "servicios.menu-day_spa": "d s",
    "servicios.menu-depilacion": "d",
    "servicios.menu-exfoliacion": "e",
    "servicios.menu-facial": "f",
    "servicios.menu-mano": "m",
    "servicios.menu-maquillaje": "m",
    "servicios.menu-novias": "n",
    "servicios.menu-peinado": "p",
    "servicios.menu-quince_anios": "q",
    "servicios.menu-reductora": "r",
    "servicios.menu-relax": "r",
    "servicios.menu-tintes": "t",
    "servicios.menu-tratamiento_capilar": "t c",

    /* menu derecha clientes activos */
    "servicios.button-general": "d g",
    "servicios.button-general-agregar": "Add Customer",
    "servicios.titulo-cliente-activo": "CC",
    "servicios.cliente.texto-cumpleanios": "Birtday",
    "servicios.cliente.texto-correo": "Email",
    "servicios.cliente.texto-celular": "Nro. Phone",
};
