module.exports = {
    
    "perfil.tabla-correo": "Correo",
    
    
    "perfil.tabla-password_old": "Password Antiguo",
    "perfil.tabla-password_new": "Password Nuevo",
    "perfil.tabla-password": "Repite el Password",

    "perfil.tabla-nick": "Nick",
    "perfil.tabla-nombre": "Nombre",
    "perfil.tabla-paterno": "Paterno",
    "perfil.tabla-materno": "Materno",
    "perfil.tabla-celular": "Celular",

};
