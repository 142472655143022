module.exports = {
    "menu.menu-reportes": "Reportes Inicio",
    "menu.reportes": "Reportes",
    "reporte.tabla-mes": "Mes",
    "reporte.tabla-anio": "Año",
    "reporte.tabla-fecha-inicio":"Fecha Inicio",
    "reporte.tabla-fecha-final":"Fecha Fin",
    "reporte.tabla-cliente":"Seleccionar Cliente",
    "reporte.tabla-personal":"Seleccionar Personal",

    "menu.sidebar.reportes": "Reportes",

};
