import { all } from 'redux-saga/effects';
import contactogeneralmenufooter from './saga';
import setRutasWeb from './saga_rutas';

export default function* rootSaga() {
    yield all([
        ...contactogeneralmenufooter,
        ...setRutasWeb,
    ]);
}
