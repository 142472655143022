
import {
    call,
    put,
    takeEvery,
    // all,
    fork,
    // takeLatest
} from 'redux-saga/effects';

import {instance_public} from '../../../../helpers/Instance';

import {

    GET_NOSOTROS_LOGIN,

    getNosotrosLoginSuccess,
    getNosotrosLoginError,

} from '../actions/actions_nosotros';

////////////////////////////////////////GET BUSCADOR////////////////////////////////////////
////////////////////////////////////////GET BUSCADOR////////////////////////////////////////
////////////////////////////////////////GET BUSCADOR////////////////////////////////////////
export function* watchgetNosotrosLogin() {
    yield takeEvery(GET_NOSOTROS_LOGIN, getNosotrosLogin);
}

function* getNosotrosLogin({payload}) {
    try {
        const { data } = yield call(nosotrosGetAsync, payload);
        if(data?.success) yield put(getNosotrosLoginSuccess(data.data));
        else yield put(getNosotrosLoginError(data.message));
    } catch (error) {
        return;
    }
}

const nosotrosGetAsync = () => instance_public.get(`nosotros`);
        // .then(authUser => authUser.data)
        // .catch(error => error);


const sagasNosotros = [
    fork(watchgetNosotrosLogin),
    // fork(watchAddNosotros),
    // fork(watchUpdateNosotros),
    // fork(watchRemoveNosotros),
]

export default sagasNosotros;