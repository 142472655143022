module.exports = {
    "servicios.base-titulo": "Servicio de Base",
    "servicios.base-input-personal": "Seleccionar Personal",
    "servicios.base-input-tipo-cabello": "Tipo Cabello",
    "servicios.base-input-grado-porosidad": "Grado Porosidad",
    "servicios.base-input-largo-cabello": "Largo Cabello",
    "servicios.base-input-pre-permanente": "Pre Permanente",
    "servicios.base-input-neutralizante": "Neutralizante",
    "servicios.base-input-liquido": "Liquido",
    "servicios.base-input-liquido-permanente": "Liquido Permanente",
    "servicios.base-input-venta": "Venta",
    "servicios.base-input-precio": "Precio Bs",
};
