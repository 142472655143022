module.exports = {
    "servicios.alizado-permanente-titulo": "Servicio de Alizado Permanente",
    "servicios.alizado-permanente-input-personal": "Seleccionar Personal",
    "servicios.alizado-permanente-input-tipo-cabello": "Tipo Cabello",
    "servicios.alizado-permanente-input-grado-porosidad": "Grado Porosidad",
    "servicios.alizado-permanente-input-largo-cabello": "Largo Cabello",
    "servicios.alizado-permanente-input-crema-alizante": "Crema Alizante",
    "servicios.alizado-permanente-input-neutralizante": "Neutralizante",
    "servicios.alizado-permanente-input-trabajo-seguir": "Trabajo a Seguir",
    "servicios.alizado-permanente-input-venta": "Venta",
    "servicios.alizado-permanente-input-precio": "Precio Bs",
};
