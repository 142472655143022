module.exports = {
    "servicios.facial-titulo": "Servicio de Facial",
    "servicios.facial-input-personal": "Seleccionar Personal",
    "servicios.facial-input-textura-piel": "Textura de la Piel",
    "servicios.facial-input-poros": "Poros",
    "servicios.facial-input-aspecto": "Aspecto",
    "servicios.facial-input-impuresa": "Impuresa",
    "servicios.facial-input-protocolo": "Protocolo",
    "servicios.facial-input-venta": "Venta",
    "servicios.facial-input-precio": "Precio Bs",
};
