module.exports = {
    "menu.menu-reportes": "Start Report",
    "menu.reportes": "Report",
    "reporte.tabla-mes": "Month",
    "reporte.tabla-anio": "Year",
    "reporte.tabla-fecha-inicio":"Fecha Inicio",
    "reporte.tabla-fecha-final":"Fecha Fin",
    "reporte.tabla-cliente":"Seleccionar Cliente",
    "reporte.tabla-personal":"Seleccionar Personal",

    "menu.sidebar.reportes": "Report",

};
