import { call, put, takeEvery, fork } from "redux-saga/effects";

import instance_public from "../../../../helpers/Instance";
import { NotificationManager } from "../../../../components/common/react-notifications";

import {
    GET_MONEDA,
    UPDATE_MONEDA,
    getMonedaSuccess,
    getMonedaError,
    updateMonedaSuccess,
    updateMonedaError,
} from "../actions/actions_moneda";

const tabla = "moneda";

export function* watchGetMoneda() {
    yield takeEvery(GET_MONEDA, GetList);
}

function* GetList() {
    try {
        const { data } = yield call(zoneGetAsync);
        if (data?.success) yield put(getMonedaSuccess(data.data));
        else yield put(getMonedaError(data.message));
    } catch (error) {
        return;
    }
}

const zoneGetAsync = () => instance_public.get(`${tabla}`);

export function* watchUpdateMoneda() {
    yield takeEvery(UPDATE_MONEDA, UpdateList);
}

function* UpdateList({ payload }) {
    try {
        const { data } = yield call(zoneUpdateAsync, payload);
        if (data?.success) {
            yield put(updateMonedaSuccess(data.data.local, payload));
            notificacionSuccess("Excelente", data.message);
        } else {
            yield put(updateMonedaError(data.message));
            notificacionWarnig("Error", data.message);
        }
    } catch (error) {
        notificacionError("Error", "");
        return;
    }
}

const zoneUpdateAsync = (form) =>
    instance_public.put(`${tabla}/${form.id}`, form);

const sagasMoneda = [fork(watchGetMoneda), fork(watchUpdateMoneda)];

export default sagasMoneda;

const notificacionSuccess = (title, message) => {
    NotificationManager.success(message, title, 3000, null, null, "");
};

const notificacionWarnig = (title, message) => {
    NotificationManager.warning(message, title, 3000, null, null, "");
};

const notificacionError = (title, message) => {
    NotificationManager.error(message, title, 3000, null, null, "");
};
